<template>

  <div>
      <div id="canvas" class="h-full"/>
  </div>

</template>

<script>
  import * as THREE from 'three';
  import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
  import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
  import _ from 'lodash';

  export default {
    data() {
      return {
        camera: null,
        controls: null,
        clock: new THREE.Clock(),
        loader: new GLTFLoader(),
        dimensions: {
          width: 0,
          height: 330,
        },
        increment: 3,
        lighting: {
          ambient: null,
          point: null,
        },
        time: {
          end: 0,
          max: null,
          step: 90,
        },
        mesh: null,
        pitches: [],
        play: false,
        endingZPos: 0,
        renderer: null,
        savantData: [],
        scene: null,
        sphere: null,
        sphereProps: {
          geom: new THREE.SphereGeometry(0.125, 32, 32),
          material: new THREE.MeshLambertMaterial({
            color: 0xffffff,
          }),
        },
      };
    },

    created() {
      this.fetchData();
      this.fetchSavantData();
    },

    mounted() {
      this.dimensions.width =  document.getElementById('canvas').clientWidth;
    },

    watch: {
      formattedSavantData: {
        handler() {
          if (this.formattedSavantData.length) {
            this.init();
            this.mainLoop();
          }
        },

        deep: true,
      },
    },

    computed: {
      formattedSavantData() {
        const swingingStrikes = _.filter(this.savantData, pitch => pitch.event_type === 'swinging_strike');

        return _.map(swingingStrikes, pitch => {
          return {
            ebisid: pitch.pitcher,
            name: pitch.name_display_first_last,
            batter_name: pitch.hitter_name_display_first_last,
            event: pitch.event_type,
            spin_rate: pitch.spin_rate,
            velocity: pitch.velocity,
            pitchname: pitch.api_pitch_type,
            initposx: pitch.polynomial_x_1,
            initposy: pitch.polynomial_y_1,
            initposz: pitch.polynomial_z_1,
            initvelx: pitch.polynomial_x_2,
            initvely: pitch.polynomial_y_2,
            initvelz: pitch.polynomial_z_2,
            initaccelx: pitch.polynomial_x_3,
            initaccely: pitch.polynomial_y_3,
            initaccelz: pitch.polynomial_z_3,
          };
        });
      },
    },

    methods: {
      fetchData() {
        this.$http.get('https://gist.githubusercontent.com/nonlocalize/8737d951b1761994f6158560111a5d43/raw/328acb95dd05684e17cbbac1683ae8ff04ba6ed9/pitches.json')
          .then(response => this.pitches = response.data, () => console.log('error'));
      },

      fetchSavantData() {
        this.$http.get('https://gist.githubusercontent.com/nonlocalize/e775ebffda4e546da69138467f66ce3b/raw/dd19031f26b13cee8d22bc9f0bb41b356795ede6/roe.json')
          .then(response => this.savantData = response.data, () => console.log('error'));
      },

      togglePlay() {
        this.play = !this.play;
      },

      addSpheresToScene() {
        _.each(this.spheresForAll, (sphere) => this.scene.add(sphere));
      },

      setupScene() {
        this.scene = new THREE.Scene();
      },

      setupCamera() {
        this.camera = new THREE.PerspectiveCamera(45, this.dimensions.width / this.dimensions.height, 0.1, 500);
        this.camera.position.set(2, -9, 3);
        this.camera.up.set(0, 0, 1);
        this.camera.lookAt(0, 50, 0);
      },

      setupLighting() {
        this.lighting = {
          ambient: new THREE.AmbientLight(0xffffff, .75),
          pointBack: new THREE.PointLight(0xffffff, .85),
        };

        this.lighting.pointBack.position.set(0, -25, 50);
        this.lighting.pointBack.castShadow = true;
        this.scene.add(this.lighting.ambient);
        this.scene.add(this.lighting.pointBack);
      },

      setupRenderer() {
        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setClearColor('#1A202C', 1);
        this.renderer.shadowMapEnabled = true;

        this.renderer.setPixelRatio(2);
        this.renderer.setSize(this.dimensions.width, this.dimensions.height);
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

        document.getElementById('canvas').appendChild(this.renderer.domElement);
      },

      createAndAddSphere(pitch) {
        this.sphere = new THREE.Mesh(this.sphereProps.geom, new THREE.MeshLambertMaterial({
          color: '#FFF',
        }));
        const coordinates = this.coordinatesAtYLocation(pitch, -1);
        this.sphere.position.set(coordinates.x, coordinates.y, coordinates.z);

        this.scene.add(this.sphere);
      },

      getTimeAtY(pitch, y) {
        const timeAt50 = (-pitch.initvely - Math.sqrt(pitch.initvely ** 2 - (4 * (50) * pitch.initaccely))) / (2 * pitch.initaccely);

        return timeAt50 - (-pitch.initvely - Math.sqrt(pitch.initvely ** 2 - (4 * (y) * pitch.initaccely))) / (2 * pitch.initaccely);
      },

      timePointObject(pitch) {
        return _.reduce([50, 40, 30, 20, 10, 0, -1], (object, yLocation) => {
          object[yLocation] = this.coordinatesAtYLocation(pitch, yLocation);
          return object;
        }, {});
      },

      zPosition(pitch, yLocation) {
        const time = this.getTimeAtY(pitch, yLocation);
        return pitch.initposz + (pitch.initvelz * time) + (pitch.initaccelz * Math.pow(time, 2) / 2);
      },

      xPosition(pitch, yLocation) {
        const time = this.getTimeAtY(pitch, yLocation);

        return pitch.initposx + (pitch.initvelx * time) + (pitch.initaccelx * Math.pow(time, 2) / 2);
      },

      coordinatesAtYLocation(pitch, yLocation) {
        return {
          x: this.xPosition(pitch, yLocation),
          y: yLocation,
          z: this.zPosition(pitch, yLocation)
        };
      },

      animateSphere() {
        if (this.endingZPos > this.sphere.position.z) {
          this.sphere.position.z = this.sphere.position.z + this.increment;
        }
      },

      color(pitch) {
        let color = '#ED8936';

        if (pitch.pitchname === 'SL') {
          color = '#38A169';
        }

        if (pitch.pitchname === 'CB') {
          color = '#3182CE';
        }

        if (pitch.pitchname === '4B') {
          color = '#E53E3E';
        }

        if (pitch.pitchname === 'clone') {
          color = '#BABABA';
        }

        return color;
      },


      createTrajectory(pitch) {
        this.createAndAddSphere(pitch);
        const numPoints = 512;

        let tubeRadius = 0.125;
        tubeRadius = 0.1;
        //tubeRadius = 0.03125;

        let geometry = new THREE.TubeGeometry(this.path(pitch), numPoints, tubeRadius, 8, false);

        const material = new THREE.MeshPhongMaterial({
          color: this.color(pitch),
          transparent: true,
          opacity: pitch.pitchname === 'clone' ? .4 : 1,
        });

        this.mesh = new THREE.Mesh(geometry, material);

        this.scene.add(this.mesh);
      },

      drawHomePlate() {
        this.loader.load('/models/homeplate.glb', gltf => {
          let mesh = gltf.scene.children[0];
          mesh.material = new THREE.MeshLambertMaterial({
            color: '#718096'
          });
          mesh.position.set(0, -.4, .04);
          mesh.castShadow = true;
          mesh.rotateX(1.5708);
          this.scene.add(mesh);

        });
      },

      drawMound() {
        this.loader.load('/models/mound.glb', gltf => {
          let mesh = gltf.scene.children[0];
          mesh.material = new THREE.MeshLambertMaterial({
            color: '#4A5568',
          });
          mesh.position.set(-1, 60, 0);
          mesh.rotateX(1.5708);

          mesh.castShadow = true;
          this.scene.add(mesh);
        });
      },

      drawGround() {
        const geometry = new THREE.PlaneGeometry(300, 300, 32);
        const material = new THREE.MeshLambertMaterial({ color: '#2D3748', side: THREE.DoubleSide });

        const plane = new THREE.Mesh(geometry, material);
        plane.receiveShadow = true;
        this.scene.add(plane);
      },

      drawStrikeZone() {
        this.loader.load('/models/strike_zone_3d.glb', gltf => {
          let mesh = gltf.scene.children[0];

          mesh.material = new THREE.MeshLambertMaterial({
            color: '#718096',
            transparent: true,
            opacity: .75,
          });
          mesh.position.set(0, -.4, 1.41);
          mesh.rotateX(1.61);

          this.scene.add(mesh);
        });
      },


      trajectoryPoints(pitch) {
        return _.map(_.orderBy(this.timePointObject(pitch), ['y'], ['desc']), coordinates => {
          return new THREE.Vector3(coordinates.x, coordinates.y, coordinates.z);
        });
      },

      path(pitch) {
        return new THREE.CatmullRomCurve3(this.trajectoryPoints(pitch));
      },

      mainLoop() {
        requestAnimationFrame(this.mainLoop);
        this.renderer.render(this.scene, this.camera);

        this.controls.update();
      },

      alternatePitch(index) {
        return _.merge(_.cloneDeep(this.pitches[index]), {
          initaccelx: 15.899,
          initaccelz: -44.1587,
          initaccely: -26,
          pitchname: 'clone',
        });
      },

      init() {
        this.setupScene();
        this.setupCamera();
        this.setupLighting();
        this.setupRenderer();
        this.drawStrikeZone();
        this.createTrajectory(this.pitches[0]);
        this.createTrajectory(this.pitches[1]);
        //this.createTrajectory(this.alternatePitch(0));
        this.drawMound();
        this.drawHomePlate();
        this.drawGround();
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.minPolarAngle = 0;
        this.controls.maxPolarAngle = Math.PI / 2;
        this.controls.zoomSpeed = .5;
        this.controls.rotateSpeed = .5;
        this.controls.target.set(0, 20, 1);
        this.controls.screenSpacePanning = true;
      },
    },
  };

</script>
