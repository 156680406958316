import Vue from 'vue';
import App from './App.vue';
import './assets/css/style.css';
import './assets/tailwind.css';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app')
