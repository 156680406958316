<template>
  <div class="font-sans overflow-x-hidden" id="app">
    <div class="w-full">
      <div class="max-w-6xl px-4 py-12 mx-auto">
        <span class="font-mono text-xl"
          ><span class="font-bold text-white bg-gray-700 inline-block p-2"
            >Luke</span
          ><span class="text-gray-900"
            ><span class="inline-block bg-gray-100 p-2">Fair</span></span
          ></span
        >
      </div>
    </div>
    <div class="max-w-6xl mx-auto px-4 mt-6">
      <p class="tracking-wide text-gray-800 max-w-3xl font-light text-lg">
        I'm an Ottawa-based UX engineer who loves making clean, intuitive,
        aesthetically-pleasing apps. I'm a rare design nerd who’s also
        comfortable architecting complex back-ends and RESTful APIs.
        <br />
        <br />
        I currently work in Baseball Operations for the
        <a
          href="https://www.mlb.com/rays/team/front-office"
          class="
            text-blue-700
            hover:text-blue-600
            font-medium
            underline
            inline-block
          "
          >Tampa Bay Rays</a
        >, designing and building their internal software with a focus on the
        UI/UX and data visualization.</p>
    </div>
    <div class="max-w-6xl mx-auto px-4 tracking-wide mt-8">
      <div class="-mx-8 flex flex-wrap flex-stretch">
        <div class="w-full md:w-1/2 px-8 mt-16">
          <a
            href="https://subvertadown.com"
            class="inline-block text-gray-700 hover:text-blue-500"
          >
            <p class="mb-4 text-gray-500 font-light">
              <span class="text-gray-800 font-medium">Subvertadown</span>
              (subvertadown.com)
            </p>
            <img
              class="flex border border-gray-200 hover:border-blue-400"
              src="./assets/images/subvertadown-1.jpg"
              alt="Subvertadown"
            />
          </a>
        </div>
        <div class="w-full md:w-1/2 px-8 mt-16">
          <a
            href="https://canadacrimereport.com"
            class="inline-block text-gray-700 hover:text-blue-500"
          >
            <p class="mb-4 text-gray-500 font-light">
              <span class="text-gray-800 font-medium">Canada Crime Report</span>
              (canadacrimereport.com)
            </p>
            <img
              class="
                flex
                border border-gray-200
                hover:border-blue-400
                md:ml-auto
              "
              src="./assets/images/canada-crime-index-1.jpg"
              alt="Canada Crime Index"
            />
          </a>
        </div>
        <div class="w-full md:w-1/2 px-8 mt-16">
          <a
            href="http://milbtracker.com"
            class="inline-block text-gray-700 hover:text-blue-500"
          >
            <p class="mb-4 text-gray-500 font-light">
              <span class="text-gray-800 font-medium">MiLB Tracker</span>
              (milbtracker.com)
            </p>
            <img
              class="flex border border-gray-200 hover:border-blue-400"
              src="./assets/images/milbtracker-1.jpg"
              alt="MiLB Tracker website"
            />
          </a>
        </div>
        <div class="w-full md:w-1/2 px-8 mt-16">
          <p class="mb-4 text-gray-500 font-light">
            <span class="text-gray-800 font-medium"
              >Interactive 3D strike zone</span
            >
            (drag or zoom)
          </p>
          <pitch-3d />
        </div>
        <div class="w-full md:w-1/2 px-8 mt-16">
          <a
            href="http://coronametrics.org"
            class="inline-block text-gray-700 hover:text-blue-500"
          >
            <p class="mb-4 text-gray-500 font-light">
              <span class="text-gray-800 font-medium"
                >CoronaMetrics -
                <span class="text-gray-500 italic"
                  >Currently offline</span
                ></span
              >
              (coronametrics.org)
            </p>
            <img
              class="flex border border-gray-200 hover:border-blue-400"
              src="./assets/images/coronametrics-1.jpg"
              alt="COVID 19 visualization page"
            />
          </a>
        </div>
        <!-- <div class="w-full md:w-1/2 px-8 mt-16">
          <a
            href="https://meteometric.com/us/fl/tampa"
            class="inline-block text-gray-700 hover:text-blue-500"
          >
            <p class="mb-4 text-gray-500 font-light">
              <span class="text-gray-800 font-medium">meteometric°</span>
              (meteometric.com)
            </p>
            <img
              class="flex border border-gray-200 hover:border-blue-400"
              src="./assets/images/meteometric-1.jpg"
              alt="Weather site"
            />
          </a>
        </div> -->
        <div class="w-full md:w-1/2 px-8 mt-16">
          <a
            href="https://dribbble.com/shots/6751428-CanadaStays-V6-redesign"
            class="inline-block text-gray-700 hover:text-blue-500"
          >
            <p class="mb-4 text-gray-500 font-light">
              <span class="text-gray-800 font-medium"
                >CanadaStays redesign</span
              >
              (canadastays.com)
            </p>
            <img
              class="flex border border-gray-200 hover:border-blue-400"
              src="./assets/images/canadastays-1.jpg"
              alt="CanadaStays"
            />
          </a>
        </div>
      </div>
    </div>
    <footer>
      <div
        class="max-w-6xl px-4 py-16 mx-auto flex items-center justify-between"
      >
        <span
          ><a
            href="mailto:fair@hey.com"
            class="
              text-blue-700
              hover:text-blue-600
              font-medium
              hover:underline
              inline-block
            "
            >Email me</a
          ></span
        >
        <div class="flex items-center justify-start">
          <a
            href="https://www.linkedin.com/in/fairluke"
            class="text-gray-500 hover:text-gray-700 text-xl"
          >
            <i class="fa fa-linkedin"></i>
          </a>
          <a
            href="https://dribbble.com/lukefair"
            class="ml-2 text-gray-500 hover:text-gray-700 text-xl"
          >
            <i class="fa fa-dribbble"></i>
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Pitch3d from "./components/Pitch3d";

export default {
  name: "App",

  components: {
    Pitch3d,
  },
};
</script>
